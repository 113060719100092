import React from 'react'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import CardSection from '../../layout/CardSection'
import { Link } from 'gatsby'
import { Button, Container, Text } from 'theme-ui'
import Breadcrumbs from '../../components/Breadcrumbs/ServiceCrumbs'

export default () => {
  const title = 'Custom Formulation'
  const styles = {
    flex: {
      display: 'flex',
    },
    running: {
      fontSize: `1.25em`,
    },
  }
  return (
    <>
      <Seo
        title='Custom Formulation'
        description='If you are thinking of launching your own branded skincare line, you might want to consider using our custom formulation services. Instead of settling for a final product you are not 100% happy with, a custom formulation allows you to go the extra mile and develop a product that is uniquely your own.'
      />
      <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs title={title} />
      </Container>
      <Divider space={2} />
      <Stack>
        <Main>
          <PageTitle header='Custom Formulation' />
          <CardSection omitTitle>
            <Text sx={styles.running}>
              <p>
                At <strong>Pacific Beauty</strong>, we aim to help you create
                something in line with your company’s brand and mission –
                whether you want a moisturizer, exfoliator, cleanser, or any
                other skincare product. Here is our process:
              </p>
              <p>
                We can either use a base formulation in our existing inventory
                or craft an entirely new formula. This depends on your
                requirements, budget, and vision for the project.
              </p>
              <p>
                If you would like to use a custom formula developed from
                scratch, it is essential that you be as specific as possible in
                your communication to us about what you want. However, you will
                not be left completely on your own. The expert skincare
                engineers at Pacific Beauty will help you decide on the right
                formulation and product depending on your target market. Our
                guidance includes advice on not just the formula but also the
                design and packaging.
              </p>
              <p>
                Alternatively, you may want to use an existing product in our
                inventory as a baseline guide and just add a few tweaks. Either
                way, we will have you submit a Product Development Request form,
                and you will have an opportunity to speak with our chemist. We
                also encourage customers to send us one or more product samples
                as a comparison benchmark. If there’s a certain product that has
                the scent you like, while another product has the texture you
                want, and yet another product has the efficacy you want, you can
                send us all of these product samples and we’ll attempt to
                deliver a unique product that matches all your requirements.
                Learning how products are formulated also helps you understand
                the process, boosting efficiency and saving you money.
              </p>
              <p>
                At Pacific Beauty, you can rest assured that our manufacturing
                facility contains the highest-quality equipment and most
                advanced technology available to ensure you are happy with the
                result. Our manufacturing facility has an ISO 22716
                certification and is registered with the FDA.{' '}
              </p>
              <p>
                Let’s work toward making your vision a reality.{' '}
                <Link to='/contact/'>Contact us</Link> today to get started.
              </p>
            </Text>
            <Divider space={3} />
            <Button as={Link} to='/contact/'>
              Contact Us
            </Button>
          </CardSection>
        </Main>
      </Stack>
    </>
  )
}
